import * as constants from "../../constants";
import { toastr } from "react-redux-toastr";
import { ICRInsurantDataModelInterfaceIPerson } from "pensiondynamics.graph.engine/models";
import { ITenantConfiguration } from "pensiondynamics.graph.engine/src/GraphBase/RequestManager/TenantConfiguration/ITenantConfiguration";
import { ICanvas } from "pensiondynamics.graph.engine/src/GraphBase/GraphConfiguration/Canvas/ICanvas";
import i18n from "i18next";
const apiUrl = process.env.REACT_APP_REST_API_URL;
const insurantPortalUrl = process.env.REACT_APP_INSURANCEPORTAL_URL;

export interface ACTION {
  type: string;
  payload?: any;
}

export const setTenant = (payload: string): ACTION => ({
  type: constants.TENANT_SET,
  payload,
});

export const setMonthlyOrYearly = (payload: string): ACTION => ({
  type: constants.SET_MONTHLY_OR_YEARLY,
  payload,
});

export const loginAct = (payload: any): ACTION => ({
  type: constants.LOGIN,
  payload,
});

export const loginSuccess = (payload: any): ACTION => ({
  type: constants.LOGIN_SUCCESS,
  payload,
});

export const logOut = (): ACTION => ({
  type: constants.LOG_OUT,
});

export const setLoading = (payload: boolean): ACTION => ({
  type: constants.LOADING,
  payload,
});
export const setI18NLoading = (payload: boolean): ACTION => ({
  type: constants.I18N_LOADING,
  payload,
});
export const setLanguageItemsList = (payload: boolean): ACTION => ({
  type: constants.SET_LANGUAGES_LIST,
  payload,
});

export const setSimulateLoading = (payload: boolean): ACTION => ({
  type: constants.SIMULATE_LOADING,
  payload,
});

export const setStandaloneCompleted = (payload: boolean): ACTION => ({
  type: constants.SET_STANDALONE_COMPLETED,
  payload,
});

export function clearData(payload, dispatch) {
  dispatch(clearDataAction(payload));
  dispatch(clearStandaloneState(payload));
}

export const clearStandaloneState = (payload: string): ACTION => ({
  type: constants.RESET_STANDALONE,
  payload,
});
export const clearDataAction = (payload: string): ACTION => ({
  type: constants.CLEAR_DATA,
  payload,
});
export const setUserDataDetaillierung = (payload: string): ACTION => ({
  type: constants.USERDATA_DETAILLIERUNG,
  payload,
});
export const setUserDataDatenquelle = (payload: string): ACTION => ({
  type: constants.USERDATA_DATENQUELLE,
  payload,
});
export const setUserDataWohnort = (payload: string): ACTION => ({
  type: constants.USERDATA_WOHNORT,
  payload,
});
export const setUserDataVPKonto = (payload: string): ACTION => ({
  type: constants.USERDATA_VPKONTO,
  payload,
});
export const setUserDataVPKontoAmount = (payload: string): ACTION => ({
  type: constants.USERDATA_VPKONTO_AMOUNT,
  payload,
});
export const setUserDataLohnklasse = (payload: string): ACTION => ({
  type: constants.USERDATA_LOHNKLASSE,
  payload,
});
export const removeTeilPension = (payload: string): ACTION => ({
  type: constants.USERDATA_TEILPENSION_REMOVE,
  payload,
});

export const setStepsOpened = (payload: string): ACTION => ({
  type: constants.SET_STEPS_OPENED,
  payload,
});

export const clearControls = (payload: string): ACTION => ({
  type: constants.CLEAR_CONTROLS,
  payload,
});

export const setCapitalPurchase = (payload: string): ACTION => ({
  type: constants.USERDATA_CAPITAL_PURCHASE,
  payload,
});

export const setCapitalWithdrawal = (payload: string): ACTION => ({
  type: constants.USERDATA_CAPITAL_WITHDRAWAL,
  payload,
});

export const setBridgingPension = (payload: any): ACTION => ({
  type: constants.USERDATA_BRIDGINGPENSION,
  payload,
});

export const setDivorcePostings = (
  payload: Array<{ date: string; value: number }>
): ACTION => ({
  type: constants.USERDATA_DIVORCEPOSTINGS,
  payload,
});

export const setHomeownership = (payload: any): ACTION => ({
  type: constants.USERDATA_HOMEOWNERSHIP,
  payload,
});

export const clearHomeownership = (payload: any): ACTION => ({
  type: constants.CLEAR_USERDATA_HOMEOWNERSHIP,
  payload,
});

export const removeCapitalPurchase = (payload?: string): ACTION => ({
  type: constants.USERDATA_CAPITAL_PURCHASE_REMOVE,
  payload,
});

export const removeInsurantPortalData = (): ACTION => ({
  type: constants.REMOVE_INSURANT_PORTAL_DATA,
});

export const forceRecalculate = (payload: string): ACTION => ({
  type: constants.FORCE_RECALCULATE,
  payload,
});

export const setInsurantData = (payload: string): ACTION => ({
  type: constants.FETCHED_INSURANT_DATA,
  payload,
});

export const forceRenderAfterInsurantLoad = (payload: boolean): ACTION => ({
  type: constants.FORCE_RENDER_AFTER_INSURANT_LOAD,
  payload,
});

export const setGlobalSettings = (payload: any): ACTION => ({
  type: constants.FETCHED_GLOBAL_SETTINGS,
  payload,
});

export const setSincronizeMaxPurchases = (): ACTION => ({
  type: constants.SINCRONIZE_MAXPURCHASES,
});

export const setBeforePensionCardsList = (payload: any): ACTION => ({
  type: constants.SET_BEFORE_PENSION_CARDS_LIST,
  payload,
});

export const setAfterPensionCardsList = (payload: any): ACTION => ({
  type: constants.SET_AFTER_PENSION_CARDS_LIST,
  payload,
});

export const setSliderValuesPurchase = (
  payload: { value: string; date: string }[]
): ACTION => ({
  type: constants.SLIDER_VALUE_PURCHASE,
  payload,
});

export const initiateGraphData = (payload: {
  tenantConfiguration: ITenantConfiguration;
  person: ICRInsurantDataModelInterfaceIPerson;
  canvas: ICanvas;
  keyDate?: string;
}): ACTION => ({
  type: constants.INITIATE_GRAPH_DATA,
  payload,
});

export const resetGraphData = (): ACTION => ({
  type: constants.RESET_GRAPH_DATE,
});

export const setAhvPensionFromStandalone = (payload: number): ACTION => ({
  type: constants.SET_AHV_PENSION_FROM_STANDALONE,
  payload,
});

export const setPayrollClassFromStandalone = (payload: number): ACTION => ({
  type: constants.SET_PAYROLL_CLASS_FROM_STANDALONE,
  payload,
});

export const setTeilPensionDate = (payload): ACTION => ({
  type: constants.SET_TEILPENSION_DATE,
  payload,
});

export const setTeilPensionGrossSalary = (payload): ACTION => ({
  type: constants.SET_TEILPENSION_GROSS_SALARY,
  payload,
});

export const setTeilPensionRetirementPercentage = (payload): ACTION => ({
  type: constants.SET_TEILPENSION_RETIREMENT_PERCENTAGE,
  payload,
});

export const setTeilPensionActivityRate = (payload): ACTION => ({
  type: constants.SET_TEILPENSION_ACTIVITY_RATE,
  payload,
});

export const setTeilPensionFinished = (): ACTION => ({
  type: constants.SET_TEILPENSION_FINISHED,
});

export const resetTeilPension = (): ACTION => ({
  type: constants.RESET_TEILPENSION,
});

export const setStandaloneTenantCode = (payload: string): ACTION => ({
  type: constants.SET_STANDALONE_TENANT_CODE,
  payload,
});

export const setStandaloneBirthDate = (payload: Date): ACTION => ({
  type: constants.SET_STANDALONE_BIRTHDATE,
  payload,
});

export const setStandaloneSex = (payload: number): ACTION => ({
  type: constants.SET_STANDALONE_SEX,
  payload,
});

export const setStandalonePlanCode = (payload: string): ACTION => ({
  type: constants.SET_STANDALONE_PLAN_CODE,
  payload,
});

export const setStandaloneCertificateDate = (payload: Date): ACTION => ({
  type: constants.SET_STANDALONE_CERTIFICATE_DATE,
  payload,
});

export const setStandaloneBalanceLastYear = (payload: number): ACTION => ({
  type: constants.SET_STANDALONE_BALANCE_LAST_YEAR,
  payload,
});

export const setStandaloneGrossSalary = (payload: number): ACTION => ({
  type: constants.SET_STANDALONE_GROSS_SALARY,
  payload,
});

export const setStandaloneActivityRate = (payload: number): ACTION => ({
  type: constants.SET_STANDALONE_ACTIVITY_RATE,
  payload,
});

export const setAdditionalSavingsPlans = (
  payload: Partial<{
    value: number;
    percent: number;
    disabled: boolean;
    planName: string;
    earlierStartDate: string | null;
    startDate: string | null;
    status: string;
  }>[]
): ACTION => ({
  type: constants.USERDATA_ADDITIONAL_SAVING_PLANS,
  payload,
});

export const simulatePost = (payload) =>
  wrap((dispatch) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: payload ? JSON.stringify(payload) : null,
    };
    return fetch(`${apiUrl}/simulate?language=${i18n.language}`, options)
      .then((res) => {
        return res.json().then((data) => {
          dispatch(setSimulateLoading(false));
          if (data.data) {
            dispatch({
              type: constants.FETCHED_CALCULATIONS,
              payload: { ...data, posted: payload.data },
            });
            dispatch({
              type: constants.CALCULATE_GRAPH_DATA,
              payload: { ...data, posted: payload.data },
            });
            return data;
          } else if (data.errors[0]) {
            setTimeout(() => {
              dispatch({
                type: constants.FETCHED_CALCULATIONS_FAILED,
                payload: { ...data, posted: payload.data },
              });
            }, 10);
            throw data.errors[0];
          } else {
            throw data;
          }
        });
      })
      .catch((error) => {
        const err = JSON.stringify(error);
        console.log(
          `%c SimulatePost Error: ${err}`,
          "background: #fff; color: #f00"
        );
        dispatch(setSimulateLoading(false));
        throw err;
      });
  }, "POST_ERROR");

export const calculateMaxPurchase = (payload) =>
  wrap((dispatch) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: payload ? JSON.stringify(payload) : null,
    };
    const date = payload.data.purchaseDate;
    return fetch(
      `${apiUrl.replace("/v1", "/v2")}/calculatemaxpurchase?language=${
        i18n.language
      }`,
      options
    )
      .then((res) => {
        return res.json().then((data) => {
          if (data.data) {
            const payload = { date: date, max: data.data.value };
            dispatch({
              type: constants.FETCHED_MAX_PURCHASE,
              payload: payload,
            });
            return data;
          }
        });
      })
      .catch((err) => {
        throw err;
      });
  }, "POST_ERROR");

export const calculateMaxDivorceValue = (payload) =>
  wrap((dispatch) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: payload ? JSON.stringify(payload) : null,
    };
    return fetch(`${apiUrl}/maxdivorcevalue?language=${i18n.language}`, options)
      .then((res) => {
        return res.json().then((data) => {
          if (data.data.value !== 0) {
            const currentCreditBalance = data.data.value
              ? data.data.value
              : null;
            dispatch({
              type: constants.USERDATA_DIVORCETOTALVALUE,
              payload: currentCreditBalance,
            });
            return currentCreditBalance;
          } else {
            return data?.errors[0];
          }
        });
      })
      .catch((err) => {
        throw err;
      });
  }, "POST_ERROR");

export const calculateMaxHomeownership = (payload) =>
  wrap((dispatch) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: payload ? JSON.stringify(payload) : null,
    };
    return fetch(
      `${apiUrl}/maxhomeownershipvalue?language=${i18n.language}`,
      options
    ).then((res) => {
      return res
        .json()
        .then((data) => {
          if (data.data.value !== 0) {
            const { promotionDate } = payload.data;
            dispatch({
              type: constants.USERDATA_HOMEOWNERSHIP,
              payload: {
                date: promotionDate,
                value: 0,
                homeownershipMaxValue: data.data.value,
              },
            });
            return {
              date: promotionDate,
              value: 0,
              homeownershipMaxValue: data.data.value,
            };
          } else {
            return data?.errors[0];
          }
        })
        .catch((err) => {
          throw err;
        });
    });
  }, "POST_ERROR");

export const fetchSettings = (tenant: string) =>
  wrap((dispatch) => {
    dispatch(setLoading(true));

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return fetch(`${apiUrl}/globalsettings?tenantName=${tenant}`, options)
      .then((res) => {
        return res.json().then((data) => {
          const payload = { data, tenantName: tenant };
          dispatch({ type: constants.FETCHED_SETTINGS, payload });
          return data;
        });
      })
      .catch((error) => {
        const err = JSON.stringify(error);
        console.log(
          `%c FetchSettings Error: ${err}`,
          "background: #fff; color: #f00"
        );
        throw err;
      });
  }, "FETCH_ERROR");

export const checkIfTenantIsValid = async (payload) => {
  const response = await fetch(
    `${insurantPortalUrl}/jsonapi/v1/isvalidtenantcode?tenantName=${payload}`
  );

  return response.json();
};

export const fetchInsurantData = (token, url, event, keyDate) =>
  wrap((dispatch) => {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    return fetch(
      `${insurantPortalUrl}/jsonapi/v1/insurantdata?token=${token}`,
      options
    )
      .then((res) => {
        return res.json().then((data) => {
          dispatch({
            type: constants.FETCHED_INSURANT_DATA,
            payload: {
              data: data.data,
              url: url,
              event: event,
              keyDate: keyDate,
            },
          });
          dispatch({
            type: constants.FORCE_RENDER_AFTER_INSURANT_LOAD,
            payload: true,
          });
          return data;
        });
      })
      .catch((error) => {
        const err = JSON.stringify(error);
        console.log(
          `%c FetchInsurantData Error: ${err}`,
          "background: #fff; color: #f00"
        );
        throw err;
      });
  }, "FETCH_ERROR");

export const calculateAdditionalSavings = (payload) =>
  wrap((dispatch) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: payload ? JSON.stringify(payload) : null,
    };
    return fetch(
      `${apiUrl}/simulateadditionalsavings?language=${i18n.language}`,
      options
    )
      .then((res) => {
        return res.json().then((data) => {
          if (data.data.value !== 0) {
            return data;
          }
        });
      })
      .catch((err) => {
        throw err;
      });
  }, "POST_ERROR");

// handling errors from redux-thunk async action creators
// source: https://thecodebarbarian.com/async-await-with-react-and-redux-thunk
export const wrap = (fn, type = "") => {
  return function (dispatch) {
    return fn(dispatch).catch((error) => {
      const err = JSON.parse(error);
      dispatch({ type, error: error.message });
      toastr.error(`Etwas ist schief gelaufen!`, err.title, { timeOut: 3000 });
    });
  };
};
